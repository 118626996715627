<!-- 实时需量 -->
<template>
  <div id="now-demand">

    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>实时需量</span>
      </div>
      <div class="content">

        <div class="query-form">
          <el-form class="form" ref="form" :inline="true" :model="formData">
            <el-form-item label="日期" class="query-date">
              <el-date-picker :append-to-body="false" placeholder="请选择日期" style="" type="date" v-model="formData.time" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button @click="drawChart" type="primary" icon="el-icon-search">查询</el-button>
            </el-form-item>
          </el-form>
          <div>
            <!-- <el-button type="warning">图表</el-button>
            <el-button type="primary">数据</el-button> -->
          </div>
        </div>
        <div id="chart"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { emissionsCarbonApi } from '@/api/emissionsCarbon.js';
import * as echarts from "echarts";
export default {
  name: "",

  data() {
    return {
      formData: {
        time: "",
      },
      stationNumber: '',
    };
  },
  created(){
    this.$store.commit('increment', '用电分析')
    this.$store.commit('selectChild', '实时需量')
    this.$store.commit("selectChildren", "");
},
  mounted() {

    this.stationNumber = sessionStorage.getItem('stationNumber')
    this.drawChart();
  },

  methods: {
    drawChart() {
      let obj = {
        stationNumber: this.stationNumber,
        staTime: '',
        endTime: '',
      }
      if (this.formData.time) {
        obj.staTime = `${this.formData.time} 00:00:00`
        obj.endTime = `${this.formData.time} 23:59:59`
      } else {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        obj.staTime = `${year}-${month}-${day} 00:00:00`
        obj.endTime = `${year}-${month}-${day} 23:59:59`
      }
      console.log(obj);
      var myEcharts = echarts.init(document.getElementById("chart"));
        // 判断当前元素上是否已经有图表实例
        if (echarts.getInstanceByDom(myEcharts)) {
          // 如果有，先销毁
          echarts.dispose(myEcharts);
        }

      emissionsCarbonApi.getNowChart(obj).then(res => {
        console.log(res);
        let dataX=[]
        let dataSer=[]
        res.data.forEach(item=>{
          dataSer.push(item.dianNeng.toFixed(3))
          dataX.push(item.ts.slice(11,16))
          // let time =item.ts.slice(11,16)
          // console.log(time);
        })


        var chart = echarts.init(document.getElementById("chart"));
        // const dataCount = 300;
        var option = {
          title: {
            text: "单位:KW",
            left: 10,
            textStyle: {
              color: "#aed6ff"
            }
          },
  
          // legend: {
          //   data: ["能耗情况"],
          //   right: '31px',
          //   top: '16px',
          //   textStyle: {
          //     color: "#AED6FF"
          //   }
          // },
          toolbox: {
            show: false,
            feature: {
              dataZoom: {
                yAxisIndex: false
              },
              saveAsImage: {
                pixelRatio: 2
              }
            }
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            bottom: 90,
            left: '4%',
            right: '3%'
          },
          dataZoom: [
            {
              type: 'inside'
            },
            {
              type: 'slider'
            }
          ],
          xAxis: {
            data: dataX,
            silent: false,
            splitLine: {
              show: false
            },
            splitArea: {
              show: false
            },
            axisLabel: {
              // interval: 30, 
              textStyle: {
                color: '#aed6ff' // 设置 x 轴标签字体颜色
              }
            }
          },
          yAxis: {
            splitArea: {
              show: false
            },
            splitLine: {
              lineStyle: {
                type: 'dashed',//
                color: "#0b2159"
              },
            },
            axisLabel: {
              color: '#aed6ff' // 
            }
          },
          // legend: {
          //   data: ["能耗情况"],
          //   right: '31px',
          //   top: '16px',
          //   textStyle: {
          //     color: "#AED6FF"
          //   }
          // },
          backgroundColor: '#071635',
          series: [
            {
              name: "",
              type: 'line',
              data: dataSer,
              // Set `large` for large data amount
              large: true,
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgb(42, 101, 180, .8)'
                  },
                  {
                    offset: 1,
                    color: 'rgb(42, 101, 180, 0)'
                  }
                ])
              },
  
            }
          ]
        };
  
  
        chart.setOption(option);
      })







    },

    queryOperation() {
      eventApi.operation(this.requestParam).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data;
          this.total = res.total;
        }
      });
    },
  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
 
<style scoped>
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#now-demand {
  width: 100%;
  min-height: 781px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
  box-sizing: border-box;
  display: flex;
}

/* tree */
.tree :deep() .el-tree {
  width: 240px;
  color: #aed6ff;
  background-color: rgba(255, 255, 255, 0);
  padding: 0 0 10px 21px;
  box-sizing: border-box;
}

.tree-title {
  font-weight: 700;
  color: #d8e3ff;
  font-size: 14px;
  margin: 24px 0 21px 21px;
}

::v-deep .el-tree {
  background: #03183f !important;
  color: #aed6ff;
}

::v-deep .el-tree-node {
  background: #03183f !important;
  color: #aed6ff;
}

::v-deep .is-current {
  background: #03183f !important;
  color: #ffc74a;
}

::v-deep .is-checked {
  background: #03183f !important;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:hover {
  background: #03183f;
  /* color: #FFC74A; */
}

::v-deep.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
  background-color: #03183f;
}

::v-deep .el-tree-node {
  background-color: #03183f;
}

::v-deep .el-tree-node:focus>.el-tree-node__content {
  background-color: #03183f;
}

.main {
  min-width: 1280px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.title {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.title img {
  width: 20px;
  height: 20px;
}

.title span {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-left: 10px;
}

/* tabs */
.tabs {
  width: 1336px;
  background-color: rgb(4, 25, 66);
  display: flex;
}

.tabs li {
  width: 85px;
  height: 40px;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0);
  font-family: Microsoft YaHei;
  color: #e8f4ff;
  text-align: center;
  line-height: 40px;
}

.tabs .select {
  background-color: #002c6c;
  color: #ffc74a;
}

/* 查询表单 */
.query-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 18px 0 20px 0;
}

.query-form :deep() .el-form {
  height: 40px;
}

.form :deep() .el-icon-date {
  color: #aed6ff;
}

.form :deep().el-form-item__label {
  color: #aed6ff;
  font-size: 12px;
}

.form :deep() .el-form-item {
  margin: 0;
  margin-right: 8px;
}

.hour :deep() .el-select {
  width: 56px;
  padding: 0;
}

.hour span {
  color: #aed6ff;
  margin: 0 5px 0 8px;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

/* input */
.form :deep() .el-input .el-input__inner {
  background-color: #061b45;
  border: 1px solid #09295b;
  color: #aed6ff;
  height: 35px;
  width: 100%;
}

#chart {
  min-width: 1318px;
  width: 100%;
  height: 624px;
}
</style>
