import { render, staticRenderFns } from "./now-demand.vue?vue&type=template&id=0d633def&scoped=true"
import script from "./now-demand.vue?vue&type=script&lang=js"
export * from "./now-demand.vue?vue&type=script&lang=js"
import style0 from "@/utils/style/public-style.css?vue&type=style&index=0&id=0d633def&prod&scoped=true&lang=css&external"
import style1 from "./now-demand.vue?vue&type=style&index=1&id=0d633def&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d633def",
  null
  
)

export default component.exports